import { render, staticRenderFns } from "./delete-all-action.vue?vue&type=template&id=96c52d5e&scoped=true&"
import script from "./delete-all-action.vue?vue&type=script&lang=js&"
export * from "./delete-all-action.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96c52d5e",
  null
  
)

export default component.exports