import { render, staticRenderFns } from "./download-action.vue?vue&type=template&id=52ec9906&scoped=true&"
import script from "./download-action.vue?vue&type=script&lang=js&"
export * from "./download-action.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ec9906",
  null
  
)

export default component.exports