import { render, staticRenderFns } from "./history-action.vue?vue&type=template&id=adc0053a&scoped=true&"
import script from "./history-action.vue?vue&type=script&lang=js&"
export * from "./history-action.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adc0053a",
  null
  
)

export default component.exports