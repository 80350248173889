import { render, staticRenderFns } from "./delete-action.vue?vue&type=template&id=504f5c3c&scoped=true&"
import script from "./delete-action.vue?vue&type=script&lang=js&"
export * from "./delete-action.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504f5c3c",
  null
  
)

export default component.exports